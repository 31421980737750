<template>
    <div>
      <b-container class="mt-5 mb-5">
        <h1 class="fs-4 font-weight-bold mb-4">Documenten aanleveren</h1>
        <b-alert variant="info" show>
            <font-awesome-icon icon="fa-solid fa-info-circle" class="mr-1" />
            Lees meer waarom we deze documenten van jullie nodig hebben.
        </b-alert>
        <b-card class="mb-4">  
            <h3 class="fs-5">Legitimatie - Naam Achternaam</h3>
            <p>Indien je je paspoort gebruikt ter identificatie, volstaat enkel de voorkant van het document, voor een ID kaart of rijbewijs hebben we de voor- en achterkant nodig. Je mag deze kopie voorzien van een watermerk en het BSN-nummer mag onleesbaar gemaakt worden. Andere gegevens dienen goed zichtbaar te zijn.</p>
            <p><strong>Indien je een ID kaart of rijbewijs upload, upload dan zowel de voor- als achterkant.</strong></p>
            <div class="mb-3">
                <label class="required">Soort legitimatie</label>
                <b-form-select /> Selecteer een optie
                Passpoort
                ID-Kaart
                Rijbewijs
            </div>
            <div class="mb-3">
                <label class="required">Voorkant</label>
                <b-form-file />
            </div>
            <div class="mb-3">
                <label class="required">Acterkant</label>
                <b-form-file />
            </div>
            <b-button variant="primary">Aanleveren</b-button>
        </b-card>
      </b-container>
    </div>
  </template>
  
  <script>
      export default {
          metaInfo: {
              title: 'Organisatie',
          },
          components: {
          },
          data() {
              return {
                events: [],
              }
          },
          computed: {},
          methods: {},
          created() {}
      }
  </script>